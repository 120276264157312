import './Spinner.scss';
import React from 'react';

export class Spinner extends React.Component {
	render() {
		return (
			<>
				<h1>Single div Spinner</h1>
				<div id={`container`}>
					<div id="spinner"/>
				</div>
			</>
		);
	}
}