import './Info.scss';
import React from 'react';
import SpinningLogo from '../../images/spinning_logo.gif';

export class Info extends React.Component {

	render() {
		return (
			<>
				<h1>Company Information</h1>

				<img src={SpinningLogo} alt="Spinning Logo" id="SpinningLogo"/>

				<p>Bagnall Software Consultants Ltd, founded in 1991, provide software services to the IT industry
					and	their clients. I take pride in producing high-quality, professional software.
					My commitment and attention to detail helps ensure that I have the confidence of new
					clients and secure repeat contracts from existing clients. My first client retained my
					services for over 3½ years.</p>
				<p>Repeat business is a key motivation. Being asked back is the most positive
					feedback and acknowledgement of success that anyone can have.</p>
				<p>Now specialising in web development, I either work freelance, for other web development
					teams or I am happy to talk to you about your individual digital media needs.</p>
			</>
		);
	}
}