import './Footer.scss';
import BSClogoFlat from '../../images/bsc_logo_flat.svg';
import {
	Link,
} from "react-router-dom";
import React from 'react';

export class Footer extends React.Component {
	render() {
		const today = new Date();
		const year = today.getFullYear();
		return (
			<footer>
				<Link to="/" target="blank" className={`copyright`}>
					© {year}&nbsp;<img src={BSClogoFlat} className={`logo`} alt={`logo`} />&nbsp;
					Bagnall Software Consultants Ltd</Link>
			</footer>
		);
	}
}