import './Reflective.scss';
import dt from './decepticon_transparent.png';
import dtr from './decepticon_transparent_reflected.png';
import fb from './fake_back.png';
import fbj from './fake_back.jpg';
import React from 'react';
import tt from './tf_transparent.png';
import ttr from './tf_transparent_reflected.png';

export class Reflective extends React.Component {
	constructor(props) {
		super(props);

		this.maskCanvasRef = React.createRef();
		this.textCanvasRef = React.createRef();
		this.maskRef = React.createRef();
		this.textRef = React.createRef();
	}

	componentDidMount = () => {
		const video = document.querySelector('video'),
			maskCanvas = this.maskCanvasRef.current.getContext('2d'),
			textCanvas = this.textCanvasRef.current.getContext('2d'),
			mask = this.maskRef.current,
			text = this.textRef.current;

		const constraints = { video: { frameRate: { ideal: 10, max: 15 } } };
		navigator.mediaDevices.getUserMedia(constraints)
			.then(function(mediaStream){
				const video = document.querySelector('video');
				video.srcObject = mediaStream;
				video.onloadedmetadata = function(e) {
					video.play();
				};
			})
			.catch(function(err){
				console.error(`An error occurred: ${ err.name } : ${ err.message}`); // eslint-disable-line no-console
			});
		// }


		video.addEventListener('loadedmetadata', function () {
			maskCanvas.canvas.width = video.videoWidth;
			maskCanvas.canvas.height = video.videoHeight;
			textCanvas.canvas.width = 1200;
			textCanvas.canvas.height = 228;
			draw();
		});

		function draw() {
			requestAnimationFrame(draw);
			maskCanvas.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, -video.videoWidth / 2, 0, maskCanvas.canvas.width * 2, maskCanvas.canvas.height);
			textCanvas.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, textCanvas.canvas.width, textCanvas.canvas.height);

			maskCanvas.drawImage(mask, 0, 0, 600, 696, 0, 0, maskCanvas.canvas.width, maskCanvas.canvas.height);
			textCanvas.drawImage(text, 0, 0);// , 1200, 228, 0, 0, 1200, 228);
		}
	};

	render() {
		return (
			<>
				<h1>Reflection</h1>
				<div id={`reflectiveContainer`}>
					<img
						alt=""
						id="mask"
						ref={this.maskRef}
						src={dtr}
					/>
					<img
						alt=""
						id="text"
						ref={this.textRef}
						src={ttr}
					/>
					<img
						alt=""
						id="fake"
						src={fb}
					/>

					<video id="video" muted loop autoPlay>
						<source src={fbj}></source>
					</video>
					<div id="maskcontainer">
						<canvas
							id="maskCanvas"
							ref={this.maskCanvasRef}
						>
							<img src={fb} className="fake" alt=""/>
							<img src={dt} alt=""/>
						</canvas>
					</div>
					<div id="textcontainer">
						<canvas
							id="textCanvas"
							ref={this.textCanvasRef}
						>
							<img src={fb} className="fake" alt=""/>
							<img src={tt} alt=""/>
						</canvas>
					</div>
				</div>
			</>
		);
	}
}