import './Row.scss';
import { Character } from '../Character';
import React from 'react';

export class Row extends React.PureComponent {

	render() {
		const {
			minColumns = 10,
			string = '',
		} = this.props;

		const characters = [];
		const nColumns = Math.max(minColumns, string.length);
		// console.log("nColumns",nColumns)
		for (let i = 0; i < nColumns; i++) {
			let character = `\xa0`;
			if (i <= string.length) character = string[i];
			characters.push(
				<Character
					character={character}
					key={`character${i}`}
				/>
			);
		}

		return (
			<div className={`row`}>
				{characters}
			</div>
		);
	}
}