import './Wardrobe.scss';
import React from 'react';

export class Wardrobe extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			data: [
				{ history: true, order: 1, value: "a" },
				{ history: true, order: 2, value: "b" },
				{ history: true, order: 3, value: "c" },
				{ history: true, order: 4, value: "d" },
				{ history: true, order: 5, value: "e" },
				{ history: true, order: 6, value: "f" },
				{ history: true, order: 7, value: "g" },
				{ history: true, order: 8, value: "h" },
				{ history: true, order: 9, value: "i" },
				{ history: true, order: 10, value: "j" },
				{ history: true, order: 11, value: "k" },
				{ history: true, order: 12, value: "l" }
			],
		};

		this.clickGarment = this.clickGarment.bind(this);
	}


	componentDidMount = () => {
		const maxOutfits = 4;
		const list = document.getElementById('list');
		const blockSize = list.offsetWidth / 4;
		this.setState({
			blockSize: blockSize,
			containerWidth: 4,
			maxOutfits: maxOutfits,
			nOutfits: 0,
		}, this.reposition);
	};

	clickGarment = (ev) => {
		const {
			data,
			maxOutfits,
		} = this.state;
		let {
			nOutfits,
		} = this.state;
		const garment = data.find((item) => item.value === ev.target.innerHTML);
		if (garment.history) {
			data.forEach((g) => {
				if (g.order < garment.order) g.order += 1;
				if (g.order > maxOutfits) g.history = true;
			});
			nOutfits = Math.min(nOutfits + 1, maxOutfits);
			garment.history = false;
			garment.order = 1;
		} else {
			data.forEach((g) => {
				if (g.order <= nOutfits && g.order > garment.order) g.order--;
			});
			nOutfits--;
			garment.history = true;
			garment.order = nOutfits + 1;
		}
		this.setState({
			data: data,
			maxOutfits: maxOutfits,
			nOutfits: nOutfits,
		}, this.reposition);
	};

	reposition = () => {
		const {
			blockSize = 0,
			containerWidth,
			data,
			nOutfits,
		} = this.state;
		const newData = [];
		data.forEach((garment) => {
			// $block = $($('.block')[i]);
			const { order } = garment;
			let l = 0, t = 0;
			if (order <= nOutfits) { // } && garment.history) {
				// Outfit
				l = ((order - 1) % containerWidth) * blockSize;
				t = 0;
			} else {
				// History
				l = ((order - 1 - nOutfits) % containerWidth) * blockSize;
				t = parseInt(((order - 1 - nOutfits) / containerWidth), 10) * blockSize + blockSize;
			}
			// console.log(order, blockSize, l, t);
			garment.left = l;
			garment.top = t;
			newData.push(garment);
			// $block.css({'left':l,'top':t});
		});
		this.setState({
			data: newData,
		});
	};

	render() {
		const {
			data,
		} = this.state;
		const dataRendered = [];
		data.forEach((garment) => {
			dataRendered.push(
				<li
					className={`block ${garment.history ? 'history' : ''}`}
					data-order={`${garment.order}`}
					key={`key${garment.value}`}
					onClick={this.clickGarment}
					style={{ left: `${garment.left}px`, top: `${garment.top}px` }}
				>{garment.value}</li>
			);
		});

		return (
			<>
				<h1>Wardrobe/Outfit prototype</h1>
				<ul id="list">
					{dataRendered}
				</ul>
				<p>This rough and ready prototype was intended to illustrate "outfit" (garments currently being worn) as the top row with round thumbnails (green) vs "wardrobe" (garments previously worn) as the subsequent rows with square thumbnails (red).</p>
				<p>The point was to show how we might reinforce the relationship between the 2 groups by animating the movement of a thumbnail from one group to another.</p>
				<p>In the end, the round vs square idea was dropped by design, and architecture prevented the integration of the two components in this way.</p>
				<p>Click on a thumbnail to see it work.</p>

			</>
		);
	}
}