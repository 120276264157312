import './Flipboard.scss';
import {
	Board,
} from './Board';
import React from 'react';

export class Flipboard extends React.Component {

	constructor(props) {
		super(props);
		const texts = [
			[
				`The quick, brown fox`,
				`jumps over`,
				`the lazy dog.`
			],
			[
				`Red leather,`,
				`yellow leather,`,
				`blue leather.`
			],
			[
				`13:44 London Kings Cross on time`,
				`13:47 Ipswich            on time`,
				`13:48 Stansted Airport   on time`,
				`13:54 Brighton           on time`
			],
			[
				`13:47 Ipswich            on time`,
				`13:48 Stansted Airport   on time`,
				`13:54 Brighton           on time`
			],
			[
				`13:48 Stansted Airport   on time`,
				`13:54 Brighton           on time`
			],
			[
				`How do you like`,
				`Flipboard?`
			]
		];
		let minColumns = 33;
		texts.forEach((text) => {
			text.forEach((line) => {
				minColumns = Math.max(minColumns, line.length);
			});
		});
		this.state = ({
			character: `!`,
			minColumns: minColumns,
			textIndex: 0,
			texts: texts,
		});

		this.setupSequence = this.setupSequence.bind(this);
	}

	setupSequence = () => {
		const {
			texts
		} = this.state;
		let {
			textIndex,
		} = this.state;
		if (this.intervalTimer) clearInterval(this.intervalTimer);
		this.intervalTimer = setInterval(() => {
			textIndex += 1;
			if (textIndex >= texts.length) textIndex = 0;
			this.setState({
				character: `W`,
				textIndex: textIndex,
			});
		}, 5000);
	};

	componentDidMount = () => {
		this.setupSequence();
	};

	componentDidUpdate = () => {
		this.setupSequence();
	};

	send = (e) => {
		// console.log("send");
		e.preventDefault();
		e.stopPropagation();
		if (this.intervalTimer) clearInterval(this.intervalTimer);

		const {
			texts,
			typing,
		} = this.state;
		texts.push(typing);
		this.setState({
			textIndex: texts.length - 1,
			texts: texts,
		});

	};

	handleChange = (e) => {
		// console.log("handleChange", e, e.target.value);
		e.preventDefault();
		e.stopPropagation();

		this.setState({
			typing: e.target.value.split('\n'),
		});
	};

	handleClear = (e) => {
		// console.log("handleClear", e, e.target.value);
		e.preventDefault();
		e.stopPropagation();
		const it = document.getElementById('inputText');
		it.value = '';

		this.setState({
			typing: '',
		});
	};

	render() {
		const {
			minColumns,
			texts,
			textIndex,
		} = this.state;

		return (
			<div className="App">
				<form>
					<textarea
						id='inputText'
						onChange={this.handleChange}
						placeholder={`You can type here and send to the flipboard`}
					/>
					<button onClick={this.send}>Send</button>
					<button onClick={this.handleClear}>Clear</button>
				</form>
				<Board
					minColumns={minColumns}
					text={texts[textIndex]}
				/>
			</div>
		);
	}
}