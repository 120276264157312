import './Testimonials.scss';
import React from 'react';
import Text from './text.svg';

export class Testimonials extends React.Component {

	render() {
		return (
			<div id="testimonials">
				<div className="container">
					<img src={Text} alt="What our clients said"/>
					<ul>
						<li><p>"always demonstrated a <b>flexible</b> and <b>pragmatic</b> approach to new tools and tasks."</p></li>
						<li><p>"developing applications with the emphasis on <b>ease of use</b>"</p></li>
						<li><p>"devotes a great deal of effort to ensure that his <b>applications are robust</b>, ..."</p></li>
						<li><p>"...and has earned the respect of his colleagues for his <b>dedication</b> to this."</p></li>
						<li><p>"comfortable in both Unix and Windows development environments"</p></li>
						<li><p>"His ability to acquire a detailed understanding of new products and his <b>attention to quality</b>,..."</p></li>
						<li><p>"...together with his ability to work in a team and manage development projects have been <b>major assets to my company</b>."</p></li>
						<li><p>"I have the <b>highest regard</b> for his technical skills and his <b>integrity</b>..."</p></li>
						<li><p>"...and would have no hesitation in recommending Richard to any future employer."</p></li>
						<li><p>"a <b>very capable</b> Smallworld developer,..."</p></li>
						<li><p>"...with good design and development skills"</p></li>
						<li><p>"He is a <b>very conscientious</b> worker and has <b>excellent attention for detail.</b>"</p></li>
						<li><p>"I have confidence in recommending him to any future employer."</p></li>
						<li><p>"Richard's <b>professional conduct</b> is <b>exemplary</b>."</p></li>
						<li><p>"He conducts himself in a <b>serious and professional</b> manner,..."</p></li>
						<li><p>"...and approaches his work in a <b>conscientious and thorough</b> way."</p></li>
						<li><p>"He has provided complete reports of his activities and the quality of both his written and technical work is very good."</p></li>
						<li><p>"fitted very well into the team and has made <b>very positive contributions</b> to the <b>success of the project</b>"</p></li>
						<li><p>"He is the only technical software person currently retained on our site giving a measure of the confidence we feel in his ability."</p></li>
						<li><p>"He suffers continuous harrasment from staff at all levels as day to day problems crop up,..."</p></li>
						<li><p>"... and copes with these in an organised and good humoured manner."</p></li>
						<li><p>"Viewed from my own non IT background, I find him to be unusually well organised and focused for an IT specialist."</p></li>
						<li><p>"he turned out to be a <b>sound programmer</b>, who puts <b>exceptional care, defensiveness and readability</b> in his code and comments"</p></li>
						<li><p>"his most remarkable strength is his <b>strong visual sense</b>: the icons and bitmaps he designs <b>touch the domain of art</b>."</p></li>
						<li><p>"not only to be a win for the product, but also for the team itself, by his continuing efforts..."</p></li>
						<li><p>"... to integrate, participate, communicate and help"</p></li>
						<li><p>"It proved to be a very wise decision to have Richard on the team,..."</p></li>
						<li><p>"...since his contribution made it possible to deliver the required project in time and within the budget"</p></li>
						<li><p>"a <b>highly capable, analytical, intelligent and effective</b> problem solver..."</p></li>
						<li><p>"...who is <b>not fearful of new challenges</b>. "</p></li>
						<li><p>"As a team member, he is <b>very dependable</b> and always mixes good humour with <b>focused and productive work</b>."</p></li>
						<li><p>"His <b>skills were diverse and in-depth</b> covering ASP, SQL, HTML,CSS and related areas."</p></li>
						<li><p>"Skills he did not have initially were soon learnt on the job"</p></li>
						<li><p>"Richard was <b>never adverse to new</b> <b>challenges</b> during his time here."</p></li>
						<li><p>"I have always found Richard to be a <b>friendly, hard</b> <b> working, fair and good humoured employee</b> who was able to both assist and learn from colleagues."</p></li>
						<li><p>"an <b>extremely conscientious and diligent worker</b>. He has a <b>great breadth of knowledge and skills</b>, coupled with a <b>wealth of experience</b>."</p></li>
					</ul>
				</div>
			</div>
		);
	}
}