import React from 'react';
import variables from './Character.scss';
// import tink from './tink.mp4';

export class Character extends React.PureComponent {
	constructor(props) {
		super(props);

		const { character: newChar } = this.props;

		this.state = ({
			animate: '',
			newChar: newChar,
			oldChar: ' ',
		});
	}

	componentDidMount = () => {
		const { character: newChar } = this.props;

		this.setState({
			newChar: newChar,
			oldChar: newChar,
		});

	};

	componentDidUpdate = (prevProps) => {
		const { character: oldChar } = prevProps;
		const { character: newChar } = this.props;
		let { transitionSpeed } = variables;
		transitionSpeed = 1000 * parseFloat(transitionSpeed.replace('s', ''));

		if (oldChar !== newChar) {
			// console.log("Set animate='animate'")
			setTimeout(() => {

				this.setState({
					animate: 'animate',
					newChar: newChar,
					oldChar: oldChar,
				}, () => {
					setTimeout(() => {
						this.setState({
							animate: ''
						});
					}, transitionSpeed);
				});
			}, parseInt(Math.random() * 1000));
		}
	};

	render() {
		const {
			className = '',
		} = this.props;

		const {
			animate = '',
		} = this.state;

		let {
			newChar,
			oldChar,
		} = this.state;

		if (newChar === ' ' || newChar === '') newChar = '\xa0';
		if (oldChar === ' ' || oldChar === '') oldChar = '\xa0';
		// console.log(`render ${oldChar}, ${newChar}`)

		return (
			<div
				className={`character ${className} ${animate}`}
			>
				<div
					className={`old-character`}
				>
					{oldChar}
				</div>
				<div
					className={`new-character`}
				>
					{newChar}
				</div>
				<div className={`flip`}>
					<div className={`old-flipper`}>{oldChar}</div>
					<div className={`new-flipper`}>{newChar}</div>
				</div>
				<span className={`sizer`}>W</span> {/* to hold shape */}
			</div>
		);
	}
}