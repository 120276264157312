import './Board.scss';
import React from 'react';
import { Row } from '../Row';

export class Board extends React.PureComponent {

	render() {
		const {
			className = '',
			minColumns = 10,
			text = '',
		} = this.props;
		const minRows = 4;

		const rows = [];
		for (let i = 0; i < Math.max(minRows, text.length); i++) {
			let string = '';
			if (i <= text.length) string = text[i];
			rows.push(
				<Row
					key={`row${i}`}
					minColumns={minColumns}
					string={string}
				/>
			);
		}

		return (
			<div
				className={`board ${className}`}
			>
				{rows}
			</div>
		);
	}
}