import './Header.scss';
import BSClogo from '../../images/bsc_logo_3d.svg';
import BSCtext from '../../images/bagnall-software-br-consultants-ltd.svg';
import {
	Link,
} from "react-router-dom";
import {
	Nav,
} from '..';
import React from 'react';

export class Header extends React.Component {
	render() {
		return (
			<>
				<Nav />
				<header>
					<Link to={`/`} id={`BSClogo`}>
						<img src={BSClogo} alt="BSC Logo"/>
					</Link>
					<Link to={`/`} id={`BSCtext`}>
						<img src={BSCtext} alt="Bagnall Software Consultants Ltd"/>
					</Link>
				</header>
			</>
		);
	}
}