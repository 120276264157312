import './FocusPull.scss';
import background from './background.jpg';
import foreground from './foreground.jpg';
import React from 'react';

export class FocusPull extends React.Component {
	render() {
		return (
			<>
				<h1>Focus Pull</h1>
				<div id="focus">
					<img src={background} alt={``} />
					<img src={foreground} alt={``} />
				</div>
			</>
		);
	}
}