import './Bulbs.scss';
import React from 'react';

export class Bulbs extends React.Component {

	// constructor(props) {
	// 	super(props);
	// 	// this.loaded = false;
	// }

	// componentDidUpdate = () => {
	// 	window.addEventListener('load', function () {
	// 		console.log("Zippers It's loaded!");
	// 		if(this.recordOriginalHeight)this.recordOriginalHeight();
	// 		// window.removeEventListener('load');

	// 	  });

	// };


	render() {
		return (
			<>
				<div class="bulbs content">
					<p>Your website, or "digital presence" is a lot like a shop window that the world can see. It's also the first impression a visitor will have of your products or services. If it's not inviting or engaging, they won't take that first step through the door to discover more.</p>

					<p>We can help you maximise your digital presence and bring business to your door. <a href="contact">Contact us to let us help you.</a></p>

					<p>Don't take my word for it:</p>

					<p class="quote">Richard has worked closely with me over a number of years to create and update the website for my garden design business. For me, as a design professional, it was vitally important that the website should display my design credentials, without being overly �gimmicky�. The result is a �front door� for my business which new clients have praised over and over again for its good looks, simplicity and clarity. Thanks to Richard, I have a website that is responsible for at least 80% of new work enquiries.</p>

					<p>Spike Jackson<br />
			September 2017</p>
				</div>
				{/* <ul title="Click for more information" class="bulbs">
			<% 'strRoot = "/portfolio" %>
			<!--#include virtual="/includes/portfolio_inc.asp" -->
				</ul> */}
			</>
		);
	}
}
