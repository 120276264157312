import './ContextHelp.scss';
import React from 'react';
// import takeItOffIcon from './take-it-off-icon.svg';
// import pressToClose from './close.svg';
// import ctaSample from './cta-sample.svg';

export class ContextHelp extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			background: 'showSimple',
			dialog: 'try',
		};

		this.changeBackground = this.changeBackground.bind(this);
		this.close = this.close.bind(this);
		this.show = this.show.bind(this);
	}

	componentDidMount = () => {
	};

	changeBackground = (e) => {
		// console.log(e.target.value);
		this.setState({
			background: e.target.value,
		});
	};

	close = (e) => {
		// console.log("close", e.target.value);
		this.setState({
			dialog: '',
		});
	};

	show = (what) => {
		// console.log("show", what);
		this.setState({
			dialog: what,
		});
	};

	render() {
		const {
			background,
			dialog,
		} = this.state;

		return (
			<>
				<h1>Context Help Popup Prototype</h1>
				<div id="metail-container" className={`${background}`}>
					<div className="dialog-container">
						<div className={`help-popup press-try ${dialog === 'try' ? 'show' : ''}`}>
							<svg onClick={this.close} className="close" width="8" height="8">
								<path fill="none" stroke="#ccc" strokeWidth="1.5" strokeLinecap="round" strokeMiterlimit="10" d="M0 0l8 8M0 8l8-8" />
							</svg>
							<svg className="cta-sample" width="42px" height="34px">
								<rect style={{ 'fill': 'white', 'fill-opacity': 1, 'stroke': 'none' }} id="rect2990" width="42" height="34" x="0" y="0"></rect>
								<g id="g17" transform="translate(-62.332203,-10.7)">
									<circle cx="83.5" cy="23.700001" r="13.1" d="m 96.6,23.700001 c 0,7.23493 -5.86507,13.1 -13.1,13.1 -7.23493,0 -13.1,-5.86507 -13.1,-13.1 C 70.4,16.46507 76.26507,10.6 83.5,10.6 c 7.23493,0 13.1,5.86507 13.1,13.100001 z" style={{ fill: `#ffffff` }}></circle>
									<path className="st1 retailer-background-colour" d="m 83.5,11.5 c 6.7,0 12.2,5.5 12.2,12.2 0,6.7 -5.5,12.2 -12.2,12.2 -6.7,0 -12.2,-5.5 -12.2,-12.2 0,-6.7 5.5,-12.2 12.2,-12.2 m 0,-1 c -7.3,0 -13.1,5.9 -13.1,13.1 0,7.2 5.9,13.1 13.1,13.1 7.3,0 13.1,-5.9 13.1,-13.1 0,-7.2 -5.8,-13.1 -13.1,-13.1 z m -4.6,17 v 3.7 h -1 v -3.7 h -1.4 v -0.9 h 3.8 v 0.9 h -1.4 z m 5.3,3.7 -1,-1.5 h -0.8 v 1.5 h -1 v -4.6 h 2.1 c 1.1,0 1.7,0.6 1.7,1.5 0,0.7 -0.4,1.2 -1,1.4 l 1.1,1.6 -1.1,0.1 z m 0,-3 c 0,-0.4 -0.3,-0.7 -0.8,-0.7 h -1 v 1.3 h 1 c 0.5,0 0.8,-0.2 0.8,-0.6 z m 4.6,1.2 v 1.8 h -1 V 29.4 L 86,26.6 h 1.2 l 1.1,1.8 1.1,-1.8 h 1.1 l -1.7,2.8 z m 0,-6.7 -4.9,-4.5 v -0.7 c 0.6,-0.1 1.1,-0.7 1.1,-1.3 0,-0.8 -0.6,-1.4 -1.4,-1.4 -0.3,0 -0.7,0.1 -1,0.4 -0.3,0.3 -0.4,0.6 -0.4,1 0,0.2 0.1,0.3 0.3,0.3 0.2,0 0.3,-0.1 0.3,-0.3 0,-0.1 0.1,-0.3 0.3,-0.5 0.1,-0.1 0.3,-0.3 0.5,-0.3 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.3,0.7 -0.7,0.7 -0.2,0 -0.3,0.1 -0.3,0.3 V 18 c -0.1,0 -0.1,0.1 -0.1,0.1 l -4.9,4.5 c -0.2,0.2 -0.3,0.5 -0.2,0.7 0.1,0.3 0.3,0.4 0.6,0.4 h 9.7 c 0.3,0 0.5,-0.2 0.6,-0.4 0.1,-0.1 0,-0.4 -0.2,-0.6 z m -10.1,0.5 4.9,-4.5 4.9,4.5 h -9.8 z"></path>
								</g>
								<path id="polygon23" d="m 42.567797,31.6 -5,-5 2.3,-2.2 -12.5,-5.4 5.4,12.5 2.4,-2.4 4.9,4.9 z"></path>
							</svg>
							<p>Press 'TRY' to dress me</p>
						</div>
						<div className={`help-popup get-your-size ${dialog === 'size' ? 'show' : ''}`}>
							<svg onClick={this.close} className="close" width="8" height="8">
								<path fill="none" stroke="#ccc" strokeWidth="1.5" strokeLinecap="round" strokeMiterlimit="10" d="M0 0l8 8M0 8l8-8" />
							</svg>
							<svg viewBox="-208 382.9 18 26" width="18px" height="26px">
								<path
									className="retailer-background-colour"
									d="M-191.7 408.4c.1-.2 1.9-3.8 1.9-7.7 0-3.2-1.2-5.2-2.2-7-.8-1.4-1.5-2.5-1.5-4 0-3.3 1.6-5 1.6-5 .3-.3.3-.7 0-1-.3-.3-.7-.3-1 0-.1.1-2.1 2.1-2.1 6 0 1.9.8 3.3 1.7 4.7l.2.4-.2.1c-1 .5-3.1 1.5-5.6 1.5-2.6 0-4.8-1-5.6-1.5l-.2-.1.2-.4c.9-1.5 1.7-2.9 1.7-4.7 0-4-2-6-2.1-6-.3-.3-.7-.3-1 0-.1.1-.2.3-.2.4 0 .2.1.4.3.6 0 0 1.6 1.7 1.6 4.9 0 1.4-.7 2.6-1.4 3.9-.2.3-.5.7-.7 1.1-.9 1.6-1.7 3.4-1.7 6.1 0 3.9 1.9 7.5 1.9 7.7.1.2.3.4.6.5.1 0 .3 0 .4-.1.2-.1.3-.2.4-.4.1-.2 0-.4 0-.5 0 0-1.8-3.6-1.8-7.1v-1.2l.3.1c.2.1.4.1.6.2h.1c1.4.5 4.4 1.7 5.8 2.8l.1.1v5.2c0 .3.2.5.4.6.1 0 .2.1.3.1.1 0 .2 0 .3-.1.2-.1.4-.3.4-.6v-5.2l.1-.1c1.8-1.5 6-3 6.5-3.1l.4-.2v1.2c0 3.5-1.8 7-1.8 7.1-.2.3 0 .8.3 1 .1.1.3.1.5 0 .2.1.4-.1.5-.3z" />
								<path
									className="retailer-background-colour"
									d="M-198.7 392.5c-.4 0-.7.3-.7.7 0 .4.3.7.7.7.4 0 .7-.2.7-.6 0-.4-.4-.8-.7-.8z" />
							</svg>
							<p>Get your size and fit</p>
							<div className="arrow up-right"></div>
						</div>
						<div className={`help-popup take-it-off ${dialog === 'off' ? 'show' : ''}`}>
							<svg onClick={this.close} className="close" width="8" height="8">
								<path fill="none" stroke="#ccc" strokeWidth="1.5" strokeLinecap="round" strokeMiterlimit="10" d="M0 0l8 8M0 8l8-8" />
							</svg>
							<p>Press
								<svg className="take-it-off-icon" width="17px" height="17px">
									<g id="g67">
										<g id="XMLID_118_" transform="matrix(1.012 0 0 1.012 -62.738 -15.887)">
											<circle className="retailer-background-colour" cx="70.4" cy="24.1" r="8.4" d="m 78.800001,24.1 c 0,4.639192 -3.760808,8.4 -8.399999,8.4 -4.639192,0 -8.4,-3.760808 -8.4,-8.4 0,-4.639191 3.760808,-8.399999 8.4,-8.399999 4.639191,0 8.399999,3.760808 8.399999,8.399999 z"></circle>
										</g>
										<path class="st2" id="rect72" fill="#fff" d="M4.762 7.49h7.894v1.417H4.762z"></path>
									</g>
								</svg>								if you want to take something off</p>
							<div className="arrow down-left"></div>
						</div>
					</div>
				</div >
				<div className="buttons">
					<button onClick={() => this.show('try')} className="showTryMe">Show Try me</button>
					<button onClick={() => this.show('size')} className="getSize">Show get your size and fit</button>
					<button onClick={() => this.show('off')} className="takeItOff">Show take it off</button>
					<label><input checked={background === "showSimple"} onClick={this.changeBackground} type="radio" name="back" value="showSimple" />Just wanda sample background</label>
					<label><input checked={background === "showTryMeBack"} onClick={this.changeBackground} type="radio" name="back" value="showTryMeBack" />Try me sample background</label>
					<label><input checked={background === "showSizeAndFitBack"} onClick={this.changeBackground} type="radio" name="back" value="showSizeAndFitBack" />Size and fit sample background</label>
					<label><input checked={background === "showTakeItOffBack"} onClick={this.changeBackground} type="radio" name="back" value="showTakeItOffBack" />Take it off sample background</label>
				</div>
				<table>
					<tbody>
						<tr>
							<td className="metail-cta"></td>
							<td className="metail-cta"></td>
							<td className="metail-cta"></td>
						</tr>
						<tr>
							<td className="metail-cta"></td>
							<td className="metail-cta"></td>
							<td className="metail-cta"></td>
						</tr>
					</tbody>
				</table>
				<div id="Notes">
					<p>Note:</p>
					<ol>
						<li>I'm using a technique that I often use, which is to take the design from the designer and
							use it as a background to layout my page items. It makes development quick and accurate.</li>
						<li>Bubble sizes differ from sample backgrounds from ticket, to enable bubble to be reusable and
							flexible around content. </li>
						<li>Arrow position is adjusted slightly so it can be in a consistent location for all bubbles.</li>
						<li>Bubbles are pure CSS (except for content) so that the border colour can be set by a single
							variable and no custom assets are needed except for the content svgs.</li>
						<li>The sample backgrounds have been taken from the tickets and resized to wanda's actual size.</li>
						{/* <li>Take it off has a 5s timeout (which seems slightly too quick to me).</li> */}
						{/* <li>Try me has clickable cta, which results in further guidance if clicked by mistake.</li> */}
						{/* <li>Chrome does not always perform the fade in of the grey arrows as we might like. I have added
							hacks to try and improve this and at least to guarantee that they appear.</li> */}
					</ol>
				</div>

			</>
		);
	}
}