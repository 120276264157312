import './Odometer.scss';
import { Digit } from '.';
import React from 'react';

export class Odometer extends React.PureComponent {
	constructor(props) {
		super(props);
		let {
			digits = 8,
			value,
		} = this.props;
		let autoRun = false;
		if (!value) {
			autoRun = true;
			value = 0;
		}

		if (value)digits = Math.max(digits, value.toString().length);
		this.state = ({
			autoRun:autoRun,
			digits: digits,
			value: value,
		});
	}

	componentDidMount = () => {
		const {
			autoRun,
		} = this.state;
		if (autoRun) {
			const _this = this;
			if (!_this.tmr) {
				_this.tmr = setInterval(() => {
					let { value: newValue } = _this.state;
					newValue += 1;
					_this.setState({
						value: newValue,
					});
				}, 1000);
			}
		}
	};

	render() {
		const {
			digits,
			value,
		} = this.state;
		const digitsJSX = [];
		const strValue = value.toString();
		const valueLength = strValue.length;
		let key = 0;
		for (let i = valueLength; i < digits; i++){
			digitsJSX.push(<Digit key={`digit-${key}`} value={0} />);
			key++;
		}
		for (let i = 0; i < valueLength; i++){
			let digitVal = strValue.substr(i, 1);
			if (digitVal === "") digitVal = 0;
			digitsJSX.push(<Digit key={`digit-${key}`} value={digitVal} />);
			key++;
		}

		return (
			<div className={`odometer-container`}>
				<div className="odometer">
					{digitsJSX}
				</div>
			</div>
		);
	}
}