import './Zippers.scss';
import React from 'react';
import { Zipper } from '.';
import Zippy from './zippy.jpg';

export class Zippers extends React.Component {

	render() {
		const zipSample = `  <Zipper
      id=\`introzip\`
      zipPercent={60}
  >
        <h2>Content</h2>
        <p>Content.</p>
  </Zipper>`;

		return (
			<div>
				<Zipper
					id="introzip"
					zipPercent={60}
				>
					<h2>Zipper UX Device</h2>
					<p>This is a fun UI device with a serious point to make. I have always been keen for designers to be able to let their
						imagination run riot and not be shackled by what they think is, or is not possible. At the end of the day,
						it may well be that what they would like is neither practical or pragmatic, but if it is "doable" within the
						constraints of the commercial environment, then we as developers should do what we can to encourage such creativity
						and make it thrive.</p>
					<p>This device is a prototype, you can see several techniques employed here, for example, if you try it on a device
						such as a tablet or smartphone using a compatible browser, you may notice the haptic feedback trying to simulate the
						movement of the zip over the teeth.</p>
					<p>The slide flips right and left as if you were pulling a real zip open or closed. This is just a simple transform with
						transition.</p>
					<p>The teeth are drawn in a simple sine wave on canvas elements at top and bottom of the zip devices.</p>
					<p>Any number of zippers can be placed on the page simply by adding a Zipper component around the content:</p>
					<pre>
						{zipSample}
					</pre>
				</Zipper>

				<Zipper id="zippyzip">
					<div >
						<h2 >Hello from Zippy!</h2>
						<img src={Zippy} alt="Zippy" />
					</div>
				</Zipper>

				<Zipper id="whitezip">
					<div >
						<h2>Zipper Content 3</h2>
						<p>This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. </p>
						<p>This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. </p>
						<p>This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. </p>
						<p>This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. </p>
						<p>This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. </p>
						<p>This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. </p>
						<p>This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. </p>
					</div>
				</Zipper>

				<Zipper id="purplezip">
					<div>
						<h2>Zipper Content 4</h2>
						<p>This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. This is the Zipper UX device. </p>
					</div>
				</Zipper>

			</div>
		);
	}
}
