import './Digit.scss';
import React from 'react';

export class Digit extends React.PureComponent {
	constructor(props) {
		super(props);
		this.smooth = true;
		this.state = ({
			smooth: true,
			wrap: false,
		});
	}

	componentDidMount = () => {
		this.setState({value: this.props.value});
	};

	componentDidUpdate = (prevProps) => {
		const { value: oldValue } = prevProps;
		const { value } = this.props;
		// console.log("HERE 1!", oldValue, value, typeof(value), typeof(oldValue));
		if ((oldValue === '9' && value === '0') || (oldValue === '0' && value === '9')) {
			// console.log("HERE 2!");
			this.setState({
				wrap: true,
			}, () => {
				setTimeout(() => {
					this.setState({
						smooth: false,
						wrap: false,
					}, () => {
						setTimeout(() => {
							this.setState({
								smooth: true,
							});
						}, 400);
					});
				}
				, 400
				);
			});
		}else{
			this.setState({value: value,});
		}
	};

	render() {
		// const { value } = this.props;
		const {
			smooth,
			wrap,
			value,
		} = this.state;
		// console.log("smooth", smooth, "wrap", wrap);

		return (
			<div className={`digit-container`}>
				<ul className={`digit digit-${value}${wrap ? '-wrap' : ''} ${smooth ? 'smooth' : ''}`} >
					<li className={`digit-9-wrap`}>9</li>
					<li className={`digit-0`}>0</li>
					<li className={`digit-1`}>1</li>
					<li className={`digit-2`}>2</li>
					<li className={`digit-3`}>3</li>
					<li className={`digit-4`}>4</li>
					<li className={`digit-5`}>5</li>
					<li className={`digit-6`}>6</li>
					<li className={`digit-7`}>7</li>
					<li className={`digit-8`}>8</li>
					<li className={`digit-9`}>9</li>
					<li className={`digit-0-wrap`}>0</li>
				</ul>
			</div>
		);
	}
}