import './Nav.scss';
import {
	Link,
} from "react-router-dom";
import React from 'react';

export class Nav extends React.Component {
	render() {
		return (
			<nav>
				<ul>
					<li className={`home`}>
						<Link to={`/`} title="Home">Home</Link>
					</li>
					<li className={`about`}>
						<Link to="/company_information/" title="Company Information">Company
							Information</Link>
					</li>
				</ul>
			</nav>
		);
	}
}